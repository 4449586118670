import axios from 'axios'

import { USER_MUTATION } from './user-mutation-types'
import { USER_ACTION } from './user-actions-types'
import { API_ENDPOINT } from '../api-endpoint'

// initial state
const state = {
  ttxid: null,
  timestamp: null,
  token: null,
}

const getters = {}

// mutations
const mutations = {
  [USER_MUTATION.SET_USER_INFO](state, payload) {
    state.ttxid = payload.ttxid
    state.token = payload.token
    state.timestamp = payload.timestamp
  },
}

const actions = {
  async [USER_ACTION.GUEST_LOGIN]({ commit }) {
    await axios.get(API_ENDPOINT.GUEST_LOGIN).then((res) => {
      commit(USER_MUTATION.SET_USER_INFO, {
        ttxid: res.data.ttxId,
        timestamp: res.data.timestamp,
        token: res.data.token,
      })
    })
  },
  [USER_ACTION.WX_LOGIN]({}, payload) {
    let href = ''
    if (payload.path) {
      href =
        location.protocol +
        '//' +
        location.host +
        payload.path +
        '?channel=' +
        payload.channel
    } else {
      href =
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        '?channel=' +
        payload.channel
    }
    href = href
      .replace('?', '~')
      .replace('&', '*')
      .replace('&', '*')
      .replace('&', '*')
      .replace('&', '*')
      .replace('&', '*')
      .replace('&', '*')
      .replace('&', '*')
    console.log('before location replace')
    location.replace(
      'https://open.weixin.qq.com/connect/oauth2/authorize?' +
        'appid=wxa2acc77c8368a9dc&redirect_uri=https%3A%2F%2Fshared-resource.taotaoxi.net%2Fwx-login-v2%2F%23redirects%3D' +
        encodeURIComponent(href) +
        '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
    )
  },
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
}
