import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { ROUTE_CONST } from '@/constants/router-constant'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '*',
    name: ROUTE_CONST.HOME.name,
    component: loadView(ROUTE_CONST.HOME.view),
  },
  {
    path: ROUTE_CONST.HISTORY.path,
    name: ROUTE_CONST.HISTORY.name,
    component: loadView(ROUTE_CONST.HISTORY.view),
  },
  {
    path: ROUTE_CONST.PREVIEW.path,
    name: ROUTE_CONST.PREVIEW.name,
    component: loadView(ROUTE_CONST.PREVIEW.view),
  },
  {
    path: ROUTE_CONST.SERVICE.path,
    name: ROUTE_CONST.SERVICE.name,
    component: loadView(ROUTE_CONST.SERVICE.view),
  },
  {
    path: ROUTE_CONST.RESULT.path,
    name: ROUTE_CONST.RESULT.name,
    component: loadView(ROUTE_CONST.RESULT.view),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // init user and channel
  if (!store.state.initialized) {
    init(to)
  }

  // check preview data and restore it then remove from localstorage
  if (
    localStorage.getItem('previewRequest') !== null &&
    to.name === 'Preview' &&
    from.name === null
  ) {
    let payload = JSON.parse(localStorage.getItem('previewRequest'))
    store.commit('fortune/setRequestData', payload)
  } else if (to.name !== 'Preview') {
    localStorage.removeItem('previewRequest')
  }

  next()
})
router.beforeResolve((to, from, next) => {
  if (store.getters.isLogin && !store.state.fortune.hasHistory) {
    console.log('isLogin & dont have history')
    store.dispatch('fortune/getHistory', store.state.user)
  }
  next()
})

const init = async function (to) {
  await store.dispatch('init', to.query)

  if (typeof to.query.ttxid !== 'undefined') {
    router.replace({
      path: to.path,
      query: {},
    })
  }
}

export default router
