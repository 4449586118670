import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import fortune from './modules/fortune'

import share from '../share'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const localStoragePlugin = (store) => {
  store.subscribe((mutation, { user }) => {
    if (mutation.type === 'user/setUserInfo') {
      window.localStorage.setItem('user', JSON.stringify(user))
    }
  })
}

export default new Vuex.Store({
  state: {
    initialized: false,
    login: false,
  },
  getters: {
    isLogin: (state) => {
      return state.login
    },
  },
  mutations: {
    setInitialized(state) {
      state.initialized = true
    },
    setLogin(state) {
      state.login = true
    },
  },
  actions: {
    // async init({ dispatch, commit }, payload) {
    //   if (localStorage.getItem('user') === null) {
    //     if (share.isWeixin()) {
    //       if (typeof payload.ttxid === 'undefined') {
    //         await dispatch('user/wxLogin')
    //       } else {
    //         commit('user/setUserInfo', payload)
    //       }
    //     } else {
    //       await dispatch('user/guestLogin')
    //     }
    //   } else {
    //     commit('user/setUserInfo', JSON.parse(localStorage.getItem('user')))
    //   }
    //   dispatch('fortune/getHistory', user.state)
    //   dispatch('fortune/getChannelData', payload)

    //   commit('setInitialized')
    // },
    async init({ dispatch, commit }, payload) {
      if (typeof payload.ttxid !== 'undefined') {
        commit('user/setUserInfo', payload)
        commit('setLogin')
      } else if (localStorage.getItem('user') !== null) {
        commit('user/setUserInfo', JSON.parse(localStorage.getItem('user')))
        commit('setLogin')
      }
      dispatch('fortune/getChannelData', payload)

      commit('setInitialized')
    },
    async login({ dispatch, commit }, payload) {
      if (localStorage.getItem('user') === null) {
        if (share.isWeixin()) {
          if (typeof payload.ttxid === 'undefined') {
            console.log('before wxlogin')
            dispatch('user/wxLogin', payload)
            return false
          } else {
            commit('user/setUserInfo', payload)
            commit('setLogin')
            return true
          }
        } else {
          await dispatch('user/guestLogin')
          commit('setLogin')
          return true
        }
      } else {
        const now = new Date()
        const user = JSON.parse(localStorage.getItem('user'))
        if (now.getTime() - user.timestamp > 15552000000) {
          localStorage.clear()
          await login()
        } else {
          commit('user/setUserInfo', user)
          commit('setLogin')
          return true
        }
      }
    },
  },
  modules: { user, fortune },
  plugins: [localStoragePlugin],
  strict: debug,
})
